import {

  ProfielBestellings as ProfielBestellingsView,
  OrderDetails as OrderDetailsView,
  ProfielAlgemeen
  
} from 'views_members';
import Adresses from './Profiel/Adresses';
import AgentInformation from './Profiel/AgentInformation';
import AgentWelcome from './Profiel/AgentWelcome';
import Commission from './Profiel/Commission';
import CommissionNetworkRetailers from './Profiel/Commission_Network_Retailers';
import { UpdateEmailAddress } from './Profiel/UpdateEmailAddress/UpdateEmailAddress';
import Giftvouchers from './Profiel/Giftvouchers';
import Challenge from './Profiel/Challenge/Challenge';

const routes = [
 
  {
    path: '/profiel-algemeen',
    renderer: (params = {}): JSX.Element => <ProfielAlgemeen {...params} />,
  },
  {
    path: '/profiel-bestellings',
    renderer: (params = {}): JSX.Element => <ProfielBestellingsView {...params} />,
  },
  {
    path: '/profiel-geskenkbewyse',
    renderer: (params = {}): JSX.Element => <Giftvouchers {...params} />,
  },
  {
    path: '/profiel-bestellings/:id',
    renderer: (params = {}): JSX.Element => <OrderDetailsView {...params} />,
  },
  {
    path: '/profiel-adresse',
    renderer: (params = {}): JSX.Element => <Adresses {...params} />,
  },
  {
    path: '/profiel-agente-kommissie',
    renderer: (params = {}): JSX.Element => <Commission {...params} />,
  },
  {
    path: '/profiel-agente-inligting',
    renderer: (params = {}): JSX.Element => <AgentInformation {...params} />,
  },
  //{
  //  path: '/profiel-agente-ranks',
  //  renderer: (params = {}): JSX.Element => <Ranks {...params} />,
  //},
  {
    path: '/profiel-challenge',
    renderer: (params = {}): JSX.Element => <Challenge {...params} />,
  },
  {
    path: '/profiel-agente-welkom',
    renderer: (params = {}): JSX.Element => <AgentWelcome {...params} />,
  },
  {
    path: '/profiel-retailers-kommissie',
    renderer: (params = {}): JSX.Element => <CommissionNetworkRetailers {...params} />,
  },
  {
    path: '/profiel/veranderepos',
    renderer: (params = {}): JSX.Element => <UpdateEmailAddress {...params} />,
  },

];

export default routes;
