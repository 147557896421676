import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'Afslag',
    subtitle:
      "As ‘n Klein-Agent kry jy 20% afslag op alle produkte en 15% op alle Klein Leer produkte.  ",
  },
  {
    title: 'Privaat of Besigheid',
    subtitle:
      "Geen besigheid… geen probleem.. sleutel asb net NVT of PVT in indien jy as ‘n Agent registreer. ",
  },
  {
    title: 'Kommisie deur sub-agente',
    subtitle:
      "Jy kan kommissie verdien op alle verkope wat deur die publiek of sub-agente onder jou gedoen word.",
  },
  {
    title: 'Registrasie Fooi / Geskenkbewys',
    subtitle:
      "Daar is ‘n eenmalige registrasiefooi van R480.00 betaalbaar wanneer jy registreer en hierdie bedrag kry jy weer terug in die vorm van ‘n geskenkbewys om te gebruik saam met jou eerste aanlyn-bestelling! ",
  },
  {
    title: 'Geen Beperkings',
    subtitle:
      "Daar is geen beperking per dorp/streek nie, want by Klein werk ons almal lekker saam en ons glo daar is vir elkeen ‘n plekkie in die son. ",
  },
  {
    title: 'Persoonlike Kontak',
    subtitle:
      "Jy en Hanna gaan baie nougeset saamwerk in hierdie splinternuwe hoofstuk van jou lewe.  Sy is baie oulik, behulpsaam en in behartig alle kommunikasie tussen ons agente en die fabriek.",
  },
];

const CompanyValues = (): JSX.Element => {
  return (
    <Box>
      <Box marginBottom={7}>
        <Typography
          align={'center'}
          color={'text.secondary'}
          sx={{ textTransform: 'uppercase'}}
          variant={'subtitle2'}
          fontWeight={600}
        >
          FANTASTIESE GELEENTHEID
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Voordele vir ons Agente
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Typography variant={'h6'} fontWeight={600} gutterBottom>
              {item.title}
            </Typography>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CompanyValues;
