import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Alert,
  Button,
  CircularProgress,
} from "@mui/material";
import agent from "api/agent";
import { CommissionStructure } from "models/commission";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchCurrentUser } from "store/accountSlice";
import { useAppDispatch, useAppSelector } from "store/configureStore";

export default function CommissionNetwork() {
  const [structure, setStructure] = useState<CommissionStructure>(null);
  const [loading, setLoading] = useState(true);

  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  if (
    !user.roles.includes("Agent") &&
    !user.roles.includes("Admin") &&
    !user.roles.includes("Retailer")
  ) {
    navigate("/");
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response: CommissionStructure =
          await agent.Commission.getstructure();
        setStructure(response);
        console.log(response);
      } catch (error) {
        toast.error(
          "Aggenee, kon nie die struktuur aflaai nie. Probeer asb weer."
        );
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  async function handleChangeToAgent() {
    setLoading(true);

    try {
      await agent.Commission.changefromretailertoagent();

      toast.success(
        "Wonderlik! Jy is nou deel van die Agent Kommissie Netwerk."
      );

      await dispatch(fetchCurrentUser());
    } catch (error) {
      toast.error("Aggenee, daar was 'n onverwagte fout. Probeer asb weer.");
    }

    setLoading(false);
  }

  const is_admin = user.roles?.includes("Admin");

  if (loading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography>Besig om jou netwerk te laai...</Typography>
      </Box>
    );

  if (structure === null) {
    return (
      <Alert severity="error">
        Aggenee, kon nie die struktuur aflaai nie. Probeer asb weer om die
        bladsy te laai.
      </Alert>
    );
  }

  return (
    <Box>
      {user.roles.includes("Retailer") && (
        <Alert severity="warning">
          Jy is nog nie deel van die opwindende kommissie program waarvan almal
          praat nie en jy mis uit op groot voordele! Om deel te word van die
          kommissie program, verander jou profiel vanaf 'Retailer' na 'Agent'.
          'Retailers' kry tot en met 25% afslag op hul aankope, maar kry geen
          kommissie op aankope deur sub-agente of gewone kliente wat hul na ons
          webwerf verwys nie. 'Agente' kry tot en met 10% afslag en kommissie op kwalifiseerende produkte wat aangekoop word deur sub-agente en gewone kliente wat hul na ons webwerf verwys het. <br /> <br />{" "}
          <Button variant="contained" onClick={handleChangeToAgent}>
            Verander na 'Agent' en word deel van kommissie netwerk
          </Button>
        </Alert>
      )}

      {is_admin && (
        <Alert severity="error">
          Die inligting hierso onder is nie heeltemal van toepassing op 'Admins'
          nie. 
        </Alert>
      )}

     
          <Typography sx={{ fontWeight: 700, marginTop: 3 }}>
            Skakels:
          </Typography>
          <Typography>
            Gebruik enige van die volgende skakels om jou netwerk te bou.
            Wanneer iemand die skakel gebruik, word hul profiel aan jou gekoppel
            en so kry jy kommissie op al hul toekomstige kwalifiseerende aankope, en ook die
            kwalifiseerende aankope van sub-agente:
          </Typography>
          {structure.isInHierarchy && (
            <ul>
              <li>https://kleinbederf.com/?agent={structure.code}</li>
              <li>https://kleinbederf.com/?agent={structure.email}</li>
            </ul>
          )}
          {!structure.isInHierarchy && (
            <Alert severity="error">
              Wonderlik jy is 'n agent en kwalifiseer vir die
              Kommissie-Gebaseerde Netwerk maar jy is nog nie deel van die
              kommissie netwerk nie. Kontak ons by info@kleinbederf.co.za vir
              hulp om deel te word van die kommissie netwerk.
            </Alert>
          )}
       
      <Typography sx={{ fontWeight: 700, marginTop: 3, marginBottom: 1 }}>
        Jou netwerk:
      </Typography>

      {structure.qualifyType === "-" && structure.isAgent && (
        <Alert severity="error">
          Jy kwalifiseer nog nie hierdie maand om kommissie te verdien nie, maar
          dis baie maklik. Om kommissie te verdien op die kwalifiseerende aankope wat
          mense in jou netwerk doen benodig die sisteem dat jy tenminste een bestelling plaas wat in totaal meer
          as R1500 is vir die maand.
        </Alert>
      )}

      {/* {structure.qualifyType === "A" && structure.isAgent && (
        <Alert severity="info">
          Wonderlik! Jy is sover op Vlak A kommissie. Om meer kommissie te
          verdien benodig die sisteem dat jy tenminste een bestelling plaas wat
          in totaal meer as R1200 (uitsluitend posgeld en koepons/geskenkbewys) is, dan skuif jy op na Vlak B se kommissie.
        </Alert>
      )} */}

      {structure.qualifyType === "B" && structure.isAgent && (
        <Alert severity="success">
            Veels geluk, jy kwalifiseer vir kommissie die maand indien daar bestellings geplaas is in die netwerk onder jou.
        </Alert>
      )}

      <Table sx={{ minWidth: 650 }} aria-label="kommissie tabel">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} width={80}>
              Netwerk Diepte
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Mense In Jou Netwerk
            </TableCell>
            <TableCell align="center" colSpan={5}>
              Jou Kommissie % op hul aankope
            </TableCell>
          </TableRow>

          <TableRow>
            {/* <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "-" ? "lightgreen" : "white",
              }}
            >
              Vlak -
            </TableCell> */}
            {/* <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              Vlak A (Sub-Agent)
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              Vlak A (Gewone Klient)
            </TableCell> */}
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              Sub-Agent
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              Gewone Klient
            </TableCell>
            <TableCell align="right">{"Retailers >R3500"} </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              #1
            </TableCell>
            <TableCell align="center">{structure?.level1}</TableCell>
            {/* <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "-" ? "lightgreen" : "white",
              }}
            >
              0%
            </TableCell> */}
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              8%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              10%
            </TableCell>
            <TableCell align="right">10%</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell component="th" scope="row">
              #2
            </TableCell>
            <TableCell align="center">{structure?.level2}</TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "-" ? "lightgreen" : "white",
              }}
            >
              0%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              4%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              4%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              4%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              4%
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              #3
            </TableCell>
            <TableCell align="center">{structure?.level3}</TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "-" ? "lightgreen" : "white",
              }}
            >
              0%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              3%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              3%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              3%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              3%
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              #4
            </TableCell>
            <TableCell align="center">{structure?.level4}</TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "-" ? "lightgreen" : "white",
              }}
            >
              0%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              2%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              2%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              2%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              2%
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              #5
            </TableCell>
            <TableCell align="center">{structure?.level5}</TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "-" ? "lightgreen" : "white",
              }}
            >
              0%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              1%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "A" ? "lightgreen" : "white",
              }}
            >
              1%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              1%
            </TableCell>
            <TableCell
              align="right"
              sx={{
                backgroundColor:
                  structure.qualifyType === "B" ? "lightgreen" : "white",
              }}
            >
              1%
            </TableCell>
            <TableCell align="right">-</TableCell>
            <TableCell align="right">-</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>

      <Box sx={{ fontSize: "0.8em" }}>
        <ul>
          <li>
            Kommissie word aangepas indien 'n bestelling gekanseeleer of
            teruggestuur word.
          </li>
          <li>
            Daar is 'n 15% admin fooi wat afgetrek word wanneer kommissie
            bereken word'.
          </li>
          <li>
            Kommissie word een keer ‘n maand uitbetaal indien dit bo R400.00 is,
            maar jy kan kies om dit op krediet te hou ook.
          </li>
          <li>
            Om kommissie te verdien gedurende die maand, hoef jy net self
            ‘n bestellings te plaas van ten minste R1500.00 (uitsluitend posgeld en koepons/geskenkbewys).
          </li>
          <li>
            Die kommissie struktuur en kommisie koerse is onderhewig aan
            verandering op enige tydstip sonder kennisgewing.
          </li>
        </ul>
      </Box>
    </Box>
  );
}
