import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/configureStore';
import { Divider } from '@mui/material';

const pages = [
  {
    id: 'algemeen',
    href: '/profiel-algemeen',
    title: 'Algemeen',
    roles: ["Gewoon","Agent","Admin","Retailer","Winkel"]
  },
  {
    id: 'adresse',
    href: '/profiel-adresse',
    title: 'Aflewering Adresse',
      roles: ["Gewoon", "Agent", "Admin", "Retailer", "Winkel"]
  },
  {
    id: 'bestellings',
    href: '/profiel-bestellings',
    title: 'Bestellings',
      roles: ["Gewoon", "Agent", "Admin", "Retailer", "Winkel"]
  },
  {
    id: 'geskenbewyse',
    href: '/profiel-geskenkbewyse',
    title: 'Geskenkbewyse',
      roles: ["Gewoon", "Agent", "Admin", "Retailer", "Winkel"]
  }
];

const pagesAgente = [
  {
    id: 'welkom',
    href: '/profiel-agente-welkom',
    title: 'Welkom (Nuwe Agente)',
    roles: ["Agent","Admin",]
  },
  {
    id: 'info',
    href: '/profiel-agente-inligting',
    title: 'Agent Sitkamer',
    roles: ["Agent","Admin",]
  },
  {
    id: 'kommissie',
    href: '/profiel-agente-kommissie',
    title: 'Kommissie Netwerk',
    roles: ["Agent","Admin",]
  },
  //{
  //  id: 'ranks',
  //  href: '/profiel-agente-ranks',
  //  title: 'Ranks',
  //  roles: ["Agent","Admin"]
  //},
  {
    id: 'challenge',
    href: '/profiel-challenge',
    title: 'Challenge',
    roles: ["Agent","Admin"]
  },
 
];

const pagesRetailers = [
  {
    id: 'retailer-kommissie',
    href: '/profiel-retailers-kommissie',
    title: 'Kommissie Netwerk',
    roles: ["Retailer","Admin",]
  },
  {
    id: 'challenge',
    href: '/profiel-challenge',
    title: 'Challenge',
    roles: ["Retailer","Admin"]
  },
 
];


interface Props {
  children: React.ReactNode;
}

const Page = ({ children }: Props): JSX.Element => {
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const theme = useTheme();

  

  const { user} = useAppSelector((state) => state.account);


  return (
    <Box>
      <Box bgcolor={theme.palette.alternate.main} paddingX={{ xs: 2, sm: 4, md: 6, lg:8 }} paddingY={{ xs: 2, sm: 4, md: 6 }} >
       
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            
          >
            Jou Profiel
          </Typography>
          <Typography variant="h6" >
            Hierso kan jy jou profiel opdateer, bestellings sien, en vele ander funksies gebruik.
          </Typography>
       
      </Box>
      <Box paddingTop={'0 !important'} marginTop={-8} paddingX={{ xs: 2, sm: 4, md: 6, lg:8 }}  paddingY={{ xs: 2, sm: 4, md: 6 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} lg={3} xl={2}>
            <Card sx={{ boxShadow: 3 }}>
              <List
                disablePadding
                sx={{
                  display: { xs: 'inline-flex', md: 'flex' },
                  flexDirection: { xs: 'row', md: 'column' },
                  overflow: 'auto',
                  flexWrap: 'nowrap',
                  width: '100%',
                  paddingY: { xs: 3, md: 2 },
                  paddingX: { xs: 4, md: 0 },
                }}
              >
                {pages.map((item) => item.roles.some((r) => user.roles?.includes(r)) && (
                
                  <ListItem
                    key={item.id}
                    component={Link}
                    to={item.href}
                    disableGutters
                    sx={{
                      marginRight: { xs: 2, md: 0 },
                      flex: 0,
                      paddingX: { xs: 0, md: 3 },
                      borderLeft: {
                        xs: 'none',
                        md: '2px solid transparent',
                      },
                      borderLeftColor: {
                        md:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : 'transparent',
                      },
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color={
                        activeLink === item.href
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {item.title}
                    </Typography>
                  </ListItem>
                 
                ))}
              </List>
            </Card>

            {["Agent", "Admin"].some((r) => user.roles?.includes(r)) &&
            <Box sx={{marginTop: 5}}>
            <Divider>Agente</Divider>
            <Card sx={{ boxShadow: 3}}>
              <List
                disablePadding
                sx={{
                  display: { xs: 'inline-flex', md: 'flex' },
                  flexDirection: { xs: 'row', md: 'column' },
                  overflow: 'auto',
                  flexWrap: 'nowrap',
                  width: '100%',
                  paddingY: { xs: 3, md: 2 },
                  paddingX: { xs: 4, md: 0 },
                }}
              >
                {pagesAgente.map((item) => (
                
                  <ListItem
                    key={item.id}
                    component={Link}
                    to={item.href}
                    disableGutters
                    sx={{
                      marginRight: { xs: 2, md: 0 },
                      flex: 0,
                      paddingX: { xs: 0, md: 3 },
                      borderLeft: {
                        xs: 'none',
                        md: '2px solid transparent',
                      },
                      borderLeftColor: {
                        md:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : 'transparent',
                      },
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color={
                        activeLink === item.href
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {item.title}
                    </Typography>
                  </ListItem>
                 
                ))}
              </List>
            </Card>
            
            </Box>}

            {["Retailer", "Admin"].some((r) => user.roles?.includes(r)) &&
            <Box sx={{marginTop: 5}}>
            <Divider>Groothandelaar (Retailers)</Divider>
            <Card sx={{ boxShadow: 3}}>
              <List
                disablePadding
                sx={{
                  display: { xs: 'inline-flex', md: 'flex' },
                  flexDirection: { xs: 'row', md: 'column' },
                  overflow: 'auto',
                  flexWrap: 'nowrap',
                  width: '100%',
                  paddingY: { xs: 3, md: 2 },
                  paddingX: { xs: 4, md: 0 },
                }}
              >
                {pagesRetailers.map((item) => (
                
                  <ListItem
                    key={item.id}
                    component={Link}
                    to={item.href}
                    disableGutters
                    sx={{
                      marginRight: { xs: 2, md: 0 },
                      flex: 0,
                      paddingX: { xs: 0, md: 3 },
                      borderLeft: {
                        xs: 'none',
                        md: '2px solid transparent',
                      },
                      borderLeftColor: {
                        md:
                          activeLink === item.href
                            ? theme.palette.primary.main
                            : 'transparent',
                      },
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap
                      color={
                        activeLink === item.href
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {item.title}
                    </Typography>
                  </ListItem>
                 
                ))}
              </List>
            </Card>
            
            </Box>}

            
          </Grid>
          
          <Grid item xs={12} md={9} lg={9} xl={10}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>{children}</Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Page;
